@import url('./Webfonts.scss');

#hero-image {
	background-image: url(../public/images/kelly-sikkema-FqqaJI9OxMI-unsplash-bw.jpg);
}

#hero-image-shadow {
	background-image: url(../public/images/kelly-sikkema-FqqaJI9OxMI-unsplash-blur-bw.png);
}

#logo-image {
	background-image: url(../public/images/logo.svg);
}
