/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Yankee Bold by Latinotype
 * URL: https://www.myfonts.com/collections/latinotype-foundry

 * Webfont: Yankee Black by Latinotype
 * URL: https://www.myfonts.com/collections/latinotype-foundry

 * Webfont: Gill Sans Light Italic by MyFonts
 * URL: https://www.myfonts.com/collections/monotype-imaging-foundry

 * Webfont: Gill Sans Light by MyFonts
 * URL: https://www.myfonts.com/collections/monotype-imaging-foundry

 * Webfont: Gill Sans Medium Italic by MyFonts
 * URL: https://www.myfonts.com/collections/monotype-imaging-foundry

 * Webfont: Gill Sans Bold Italic by MyFonts
 * URL: https://www.myfonts.com/collections/monotype-imaging-foundry

 * Webfont: Gill Sans Bold by MyFonts
 * URL: https://www.myfonts.com/collections/monotype-imaging-foundry

 * Webfont: Gill Sans Medium by MyFonts
 * URL: https://www.myfonts.com/collections/monotype-imaging-foundry

 * Webfont: Avenir Std 45 Book by MyFonts
 * URL: https://www.myfonts.com/collections/linotype-foundry

 * Webfont: Avenir Std 95 Black by MyFonts
 * URL: https://www.myfonts.com/collections/linotype-foundry

 * Webfont: Avenir Std 55 Roman by MyFonts
 * URL: https://www.myfonts.com/collections/linotype-foundry

 * Webfont: Avenir Std 35 Light by MyFonts
 * URL: https://www.myfonts.com/collections/linotype-foundry

 * Webfont: Avenir Std 85 Heavy by MyFonts
 * URL: https://www.myfonts.com/collections/linotype-foundry
 
 * © 2025 MyFonts Inc. */

@font-face {
	font-family: 'AvenirStd35Light';
	src: url('../public/webFonts/AvenirStd35Light/font.woff2') format('woff2'),
		url('../public/webFonts/AvenirStd35Light/font.woff') format('woff');
}
@font-face {
	font-family: 'AvenirStd55Roman';
	src: url('../public/webFonts/AvenirStd55Roman/font.woff2') format('woff2'),
		url('../public/webFonts/AvenirStd55Roman/font.woff') format('woff');
}
@font-face {
	font-family: 'GillSansBoldItalic';
	src: url('../public/webFonts/GillSansBoldItalic/font.woff2') format('woff2'),
		url('../public/webFonts/GillSansBoldItalic/font.woff') format('woff');
}
@font-face {
	font-family: 'GillSansMediumItalic';
	src: url('../public/webFonts/GillSansMediumItalic/font.woff2') format('woff2'),
		url('../public/webFonts/GillSansMediumItalic/font.woff') format('woff');
}
@font-face {
	font-family: 'GillSansMedium';
	src: url('../public/webFonts/GillSansMedium/font.woff2') format('woff2'),
		url('../public/webFonts/GillSansMedium/font.woff') format('woff');
}
@font-face {
	font-family: 'GillSansBold';
	src: url('../public/webFonts/GillSansBold/font.woff2') format('woff2'),
		url('../public/webFonts/GillSansBold/font.woff') format('woff');
}
@font-face {
	font-family: 'GillSansLightItalic';
	src: url('../public/webFonts/GillSansLightItalic/font.woff2') format('woff2'),
		url('../public/webFonts/GillSansLightItalic/font.woff') format('woff');
}
@font-face {
	font-family: 'AvenirStd85Heavy';
	src: url('../public/webFonts/AvenirStd85Heavy/font.woff2') format('woff2'),
		url('../public/webFonts/AvenirStd85Heavy/font.woff') format('woff');
}
@font-face {
	font-family: 'GillSansLight';
	src: url('../public/webFonts/GillSansLight/font.woff2') format('woff2'),
		url('../public/webFonts/GillSansLight/font.woff') format('woff');
}
@font-face {
	font-family: 'YankeeBlack';
	src: url('../public/webFonts/YankeeBlack/font.woff2') format('woff2'),
		url('../public/webFonts/YankeeBlack/font.woff') format('woff');
}
@font-face {
	font-family: 'AvenirStd45Book';
	src: url('../public/webFonts/AvenirStd45Book/font.woff2') format('woff2'),
		url('../public/webFonts/AvenirStd45Book/font.woff') format('woff');
}
@font-face {
	font-family: 'YankeeBold';
	src: url('../public/webFonts/YankeeBold/font.woff2') format('woff2'),
		url('../public/webFonts/YankeeBold/font.woff') format('woff');
}
@font-face {
	font-family: 'AvenirStd95Black';
	src: url('../public/webFonts/AvenirStd95Black/font.woff2') format('woff2'),
		url('../public/webFonts/AvenirStd95Black/font.woff') format('woff');
}
